.header-call {
  min-width: rem(135);
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  color: var(--text-dark-primary);
  text-decoration: none;

  &__phone {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    text-decoration: none;
    text-wrap: nowrap;

    transition: color var(--animation-timing) var(--cubic-bezier);
    
    @include hover {
      color: var(--accent-light-secondary);
    }
  }

  &__link {
    padding: 0;
    border: none;
    background: none;
    
    color: var(--accent-light-primary);
    font: 400 rem(16)/150% var(--font-primary);
    text-decoration: underline;
    text-wrap: nowrap;

    transition: color var(--animation-timing) var(--cubic-bezier);
    
    cursor: pointer;

    @include mediaBigDesktop {
      font: 400 big(16)/150% var(--font-primary);
    }
    
    @include hover {
      color: var(--accent-light-secondary);
    }
  }
}
