.header-more {
  display: none;

  &_show {
    display: flex;
  }
  
  & .nav {
    &__arrow {
      width: rem(24);
      height: rem(24);

      @include mediaBigDesktop {
        width: big(24);
        height: big(24);
      }
    }
  }
}
