.nav {
  &__list {
    margin: 0;

    padding: 0;
    display: flex;
    flex-direction: row;
    gap: rem(40);

    list-style: none;

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaLaptop {
      gap: rem(25);
    }
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    @include hover {
      & > .nav-dropdown {
        margin: 0;

        opacity: 1;
        visibility: visible;
      }
    }

    & > .nav-dropdown {
      position: absolute;
      top: calc(100% + #{rem(25)});
      left: rem(-30);
      margin-top: rem(-10);

      @include mediaBigDesktop {
        top: calc(100% + #{big(25)});
        left: big(-30);
        margin-top: big(-10);
      }

      @include mediaLaptop {
        top: calc(100% + #{rem(22)});
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        top: rem(-20);
        left: 0;

        width: 100%;
        height: rem(80);

        background-color: transparent;
        opacity: 0;

        @include mediaBigDesktop {
          top: big(-20);

          height: big(40);
        }
      }
    }
  }

  &__link {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(5);

    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    text-decoration: none;
    white-space: nowrap;

    transition: color var(--animation-timing) var(--cubic-bezier);

    cursor: pointer;

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include hover {
      color: var(--accent-light-primary);
    }

    &::before {
      @include pseudo(20) {}

      @include mediaLaptop {
        @include pseudo(12.5) {}
      }
    }
  }

  &__arrow {
    width: rem(14);
    height: rem(14);
    flex-shrink: 0;

    fill: var(--accent-light-primary);

    @include mediaBigDesktop {
      width: big(14);
      height: big(14);
    }
  }

  &-dropdown {
    padding: rem(15);
    min-width: rem(245);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0;

    background-color: var(--bg-light-extra);
    opacity: 0;
    visibility: hidden;

    transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s,
      opacity var(--animation-timing) var(--cubic-bezier) 0.25s,
      visibility var(--animation-timing) var(--cubic-bezier) 0.25s;

    @include mediaBigDesktop {
      padding: big(15);
      min-width: big(245);
    }

    @include mediaLaptop {
      padding: rem(10);
    }

    & .nav-dropdown {
      top: 0;
      left: 100%;

      margin-top: 0;
      margin-left: rem(-10);

      transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s,
        opacity var(--animation-timing) var(--cubic-bezier) 0.25s,
        visibility var(--animation-timing) var(--cubic-bezier) 0.25s;

      &::before {
        content: unset;
      }
    }

    & .select-answer__item {
      background-color: var(--bg-light-extra);

      @include hover {
        color: var(--accent-light-primary);
        background-color: var(--bg-white);
      }
    }

    & .nav {

      &__item {
        position: relative;
        width: 100%;
        align-items: start;
        justify-content: start;
        gap: rem(10);

        @include mediaBigDesktop {
          gap: big(10);
        }

        @include hover {
          & > .nav {
            &__link {
              color: var(--accent-light-primary);

              background-color: var(--bg-white);
            }
          }
        }
      }

      &__link {
        padding: rem(10) rem(15);
        width: 100%;
        align-items: center;
        justify-content: space-between;

        color: var(--text-dark-primary);
        font: var(--font-body-XS);

        transition: color var(--animation-timing) var(--cubic-bezier);

        @include mediaBigDesktop {
          padding: big(10) rem(15);
        }

        @include mediaLaptop {
          padding: rem(11.5) rem(15);
        }

        &::before {
          display: none;
        }
      }

      &__arrow {
        width: rem(18);
        height: rem(18);

        fill: var(--accent-dark-primary);

        @include mediaBigDesktop {
          width: big(18);
          height: big(18);
        }
      }
    }
  }
}

[data-nav-overflow-list] .nav__link {
    padding: 0;
}