@import '../../mixins';

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;

  padding: rem(30) 0;
  width: 100%;

  background-color: var(--bg-white);

  @include mediaBigDesktop {
    padding: big(30) 0;
  }

  @include mediaLaptop {
    padding: rem(30) 0;
  }

  @include mediaTablet {
    padding: rem(23.5) 0;
  }

  @include mediaMobile {
    padding: rem(20) 0;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    & > [data-nav-overflow-container] {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: rem(50);

      @include mediaBigDesktop {
        gap: big(50);
      }
    }
  }

  &__nav {
    flex-shrink: 0;

    @include mediaTablet {
      display: none;
    }
  }

  &__basis {
    @include media(#{rem(1820)}) {
      flex-basis: 50%;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: rem(60);

    @include mediaBigDesktop {
      gap: big(60);
    }
  }

  &__social {
    --header-social-padding: #{rem(30)};
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--header-social-padding);

    @include mediaBigDesktop {
      --header-social-padding: #{big(30)};
    }

    @include media(#{rem(768)}) {
      display: none;
    }

    & .header-social {
      padding-right: var(--header-social-padding);

      border-right: 1px solid rgba(39, 42, 56, 0.15);

      @include mediaBigDesktop {
        border-right-width: big(1);
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: rem(40);

    @include mediaBigDesktop {
      gap: big(40);
    }

    @include mediaLaptop {
      gap: rem(25);
    }

    @include mediaMobile {
      gap: rem(20);
    }
  }

  &__button {
    position: relative;

    width: rem(24);
    height: rem(24);

    overflow: visible;

    @include mediaBigDesktop {
      width: big(24);
      height: big(24);
    }

    @include mediaLaptop {
      width: rem(18);
      height: rem(18);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }

    @include hover {
      & .button {
        &__icon {
          fill: var(--accent-light-secondary);
        }
      }
    }

    &::before {
      @include pseudo(20) {}

      @include mediaLaptop {
        @include pseudo(12.5)
      }

      @include mediaMobile {
        @include pseudo(10)
      }
    }

    & .button {
      &__icon {
        width: 100%;
        height: 100%;

        fill: var(--accent-dark-primary);
      }
    }
  }

  & .burger-button {
    display: none;
    
    @include mediaTablet {
        display: flex;
    }
  }
}

@import "header-more",
"nav",
"nav-europa",
"header-cart",
"header-social",
"header-call",
"header-phone";
