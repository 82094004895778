.header-phone {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(10);

  text-decoration: none;

  @include mediaBigDesktop {
    gap: big(10);
  }

  @include hover {
    & .header-phone {
      &__icon {
        fill: var(--accent-light-secondary);
      }
      &__text {
        color: var(--accent-light-secondary);
      }
    }
  }

  &::before {
    @include pseudo(10) {}

    @include mediaMobile {
      @include pseudo(8) {}
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--accent-dark-primary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaLaptop {
      width: rem(18);
      height: rem(18);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }


    @include mediaMobile {
      fill: var(--accent-light-primary);
    }
  }

  &__text {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap;

    transition: color var(--animation-timing) var(--cubic-bezier);

    @include mediaMobile {
      display: none;
    }
  }
}
