.nav-europa {
  --list-gap: #{rem(10)};

  position: absolute;
  top: 100%;
  left: 0;

  padding: rem(40) 0;
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  background-color: var(--bg-white);
  border-top: solid 1px var(--stroke-dark-secondary);
  opacity: 0;
  visibility: hidden;

  transition: opacity var(--animation-timing) var(--cubic-bezier) 0.25s,
    visibility var(--animation-timing) var(--cubic-bezier) 0.25s;

  @include mediaBigDesktop {
    --list-gap: #{big(10)};

    padding: big(40) 0;

    border-top-width: big(1);
  }

  &__wrapper {
    padding: 0 var(--container-offset);
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-count: 4;
    gap: var(--grid-column-gap);

    overflow: auto;
    overscroll-behavior: contain;

    @include scrollbar();
  }

  & ul {
    margin: 0;

    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--list-gap);

    list-style: none;
  }

  li {
    width: 100%;
    display: block;

    & > ul {
      margin-top: var(--list-gap);

      & > li {
        & > ul {
          padding-left: calc(var(--grid-column-gap) / 2);
        }
      }
    }

    a {
      width: 100%;
      display: block;

      color: var(--text-dark-secondary);
      font: var(--font-body-S);
      text-decoration: none;

      transition: color var(--animation-timing) var(--cubic-bezier);

      @include hover {
        color: var(--accent-light-primary);
      }
    }
  }

  &__column {
    width: 100%;
    height: auto;

    & > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: var(--grid-column-gap);

      & > li {
        & > a {
          color: var(--text-dark-primary);
          font: var(--font-body-M);
          font-weight: 500;
        }
      }
    }

    &_solo {
      & > ul {
        gap: var(--list-gap);
      }
    }
  }
}

[data-ue-catalog] {
  position: unset;

  @include hover {
    & .nav-europa {
      opacity: 1;
      visibility: visible;

      pointer-events: all;
    }

    & > .nav__link {
      &::before {
        @include pseudo (45) {}
      }
    }
  }
}
