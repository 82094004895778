.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
  padding: 1.875rem 0;
  width: 100%;
  background-color: var(--bg-white); }
  @media screen and (min-width: 160.0625rem) {
    .header {
      padding: 1.17188vw 0; } }
  @media screen and (max-width: 106.25rem) {
    .header {
      padding: 1.875rem 0; } }
  @media screen and (max-width: 85rem) {
    .header {
      padding: 1.46875rem 0; } }
  @media screen and (max-width: 39.375rem) {
    .header {
      padding: 1.25rem 0; } }
  .header__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; }
    .header__container > [data-nav-overflow-container] {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 3.125rem; }
      @media screen and (min-width: 160.0625rem) {
        .header__container > [data-nav-overflow-container] {
          gap: 1.95312vw; } }
  .header__nav {
    flex-shrink: 0; }
    @media screen and (max-width: 85rem) {
      .header__nav {
        display: none; } }
  @media screen and (max-width: 113.75rem) {
    .header__basis {
      flex-basis: 50%; } }
  .header__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 3.75rem; }
    @media screen and (min-width: 160.0625rem) {
      .header__right {
        gap: 2.34375vw; } }
  .header__social {
    --header-social-padding: 1.875rem;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--header-social-padding); }
    @media screen and (min-width: 160.0625rem) {
      .header__social {
        --header-social-padding: 1.17188vw; } }
    @media screen and (max-width: 48rem) {
      .header__social {
        display: none; } }
    .header__social .header-social {
      padding-right: var(--header-social-padding);
      border-right: 1px solid rgba(39, 42, 56, 0.15); }
      @media screen and (min-width: 160.0625rem) {
        .header__social .header-social {
          border-right-width: 0.03906vw; } }
  .header__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 2.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .header__menu {
        gap: 1.5625vw; } }
    @media screen and (max-width: 106.25rem) {
      .header__menu {
        gap: 1.5625rem; } }
    @media screen and (max-width: 39.375rem) {
      .header__menu {
        gap: 1.25rem; } }
  .header__button {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    overflow: visible; }
    @media screen and (min-width: 160.0625rem) {
      .header__button {
        width: 0.9375vw;
        height: 0.9375vw; } }
    @media screen and (max-width: 106.25rem) {
      .header__button {
        width: 1.125rem;
        height: 1.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .header__button {
        width: 1rem;
        height: 1rem; } }
    @media (any-hover: hover) {
      .header__button:hover .button__icon {
        fill: var(--accent-light-secondary); } }
    .header__button::before {
      content: "";
      position: absolute;
      top: -1.25rem;
      left: -1.25rem;
      width: calc(100% + 1.25rem * 2);
      height: calc(100% + 1.25rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .header__button::before {
          top: -0.78125vw;
          left: -0.78125vw;
          width: calc(100% + 0.78125vw * 2);
          height: calc(100% + 0.78125vw * 2); } }
      @media screen and (max-width: 106.25rem) {
        .header__button::before {
          content: "";
          position: absolute;
          top: -0.78125rem;
          left: -0.78125rem;
          width: calc(100% + 0.78125rem * 2);
          height: calc(100% + 0.78125rem * 2);
          background-color: transparent;
          opacity: 0; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .header__button::before {
      top: -0.48828vw;
      left: -0.48828vw;
      width: calc(100% + 0.48828vw * 2);
      height: calc(100% + 0.48828vw * 2); } }
      @media screen and (max-width: 39.375rem) {
        .header__button::before {
          content: "";
          position: absolute;
          top: -0.625rem;
          left: -0.625rem;
          width: calc(100% + 0.625rem * 2);
          height: calc(100% + 0.625rem * 2);
          background-color: transparent;
          opacity: 0; } }
  @media screen and (max-width: 39.375rem) and (min-width: 160.0625rem) {
    .header__button::before {
      top: -0.39062vw;
      left: -0.39062vw;
      width: calc(100% + 0.39062vw * 2);
      height: calc(100% + 0.39062vw * 2); } }
    .header__button .button__icon {
      width: 100%;
      height: 100%;
      fill: var(--accent-dark-primary); }
  .header .burger-button {
    display: none; }
    @media screen and (max-width: 85rem) {
      .header .burger-button {
        display: flex; } }

.header-more {
  display: none; }
  .header-more_show {
    display: flex; }
  .header-more .nav__arrow {
    width: 1.5rem;
    height: 1.5rem; }
    @media screen and (min-width: 160.0625rem) {
      .header-more .nav__arrow {
        width: 0.9375vw;
        height: 0.9375vw; } }

.nav__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  list-style: none; }
  @media screen and (min-width: 160.0625rem) {
    .nav__list {
      gap: 1.5625vw; } }
  @media screen and (max-width: 106.25rem) {
    .nav__list {
      gap: 1.5625rem; } }

.nav__item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (any-hover: hover) {
    .nav__item:hover > .nav-dropdown {
      margin: 0;
      opacity: 1;
      visibility: visible; } }
  .nav__item > .nav-dropdown {
    position: absolute;
    top: calc(100% + 1.5625rem);
    left: -1.875rem;
    margin-top: -0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .nav__item > .nav-dropdown {
        top: calc(100% + 0.97656vw);
        left: -1.17188vw;
        margin-top: -0.39062vw; } }
    @media screen and (max-width: 106.25rem) {
      .nav__item > .nav-dropdown {
        top: calc(100% + 1.375rem); } }
    .nav__item > .nav-dropdown::before {
      content: "";
      position: absolute;
      z-index: 0;
      top: -1.25rem;
      left: 0;
      width: 100%;
      height: 5rem;
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .nav__item > .nav-dropdown::before {
          top: -0.78125vw;
          height: 1.5625vw; } }

.nav__link {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
  color: var(--text-dark-primary);
  font: var(--font-body-XS);
  text-decoration: none;
  white-space: nowrap;
  transition: color var(--animation-timing) var(--cubic-bezier);
  cursor: pointer; }
  @media screen and (min-width: 160.0625rem) {
    .nav__link {
      gap: 0.19531vw; } }
  @media (any-hover: hover) {
    .nav__link:hover {
      color: var(--accent-light-primary); } }
  .nav__link::before {
    content: "";
    position: absolute;
    top: -1.25rem;
    left: -1.25rem;
    width: calc(100% + 1.25rem * 2);
    height: calc(100% + 1.25rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .nav__link::before {
        top: -0.78125vw;
        left: -0.78125vw;
        width: calc(100% + 0.78125vw * 2);
        height: calc(100% + 0.78125vw * 2); } }
    @media screen and (max-width: 106.25rem) {
      .nav__link::before {
        content: "";
        position: absolute;
        top: -0.78125rem;
        left: -0.78125rem;
        width: calc(100% + 0.78125rem * 2);
        height: calc(100% + 0.78125rem * 2);
        background-color: transparent;
        opacity: 0; } }
  @media screen and (max-width: 106.25rem) and (min-width: 160.0625rem) {
    .nav__link::before {
      top: -0.48828vw;
      left: -0.48828vw;
      width: calc(100% + 0.48828vw * 2);
      height: calc(100% + 0.48828vw * 2); } }

.nav__arrow {
  width: 0.875rem;
  height: 0.875rem;
  flex-shrink: 0;
  fill: var(--accent-light-primary); }
  @media screen and (min-width: 160.0625rem) {
    .nav__arrow {
      width: 0.54688vw;
      height: 0.54688vw; } }

.nav-dropdown {
  padding: 0.9375rem;
  min-width: 15.3125rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0;
  background-color: var(--bg-light-extra);
  opacity: 0;
  visibility: hidden;
  transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s; }
  @media screen and (min-width: 160.0625rem) {
    .nav-dropdown {
      padding: 0.58594vw;
      min-width: 9.57031vw; } }
  @media screen and (max-width: 106.25rem) {
    .nav-dropdown {
      padding: 0.625rem; } }
  .nav-dropdown .nav-dropdown {
    top: 0;
    left: 100%;
    margin-top: 0;
    margin-left: -0.625rem;
    transition: margin var(--animation-timing) var(--cubic-bezier) 0.25s, opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s; }
    .nav-dropdown .nav-dropdown::before {
      content: unset; }
  .nav-dropdown .select-answer__item {
    background-color: var(--bg-light-extra); }
    @media (any-hover: hover) {
      .nav-dropdown .select-answer__item:hover {
        color: var(--accent-light-primary);
        background-color: var(--bg-white); } }
  .nav-dropdown .nav__item {
    position: relative;
    width: 100%;
    align-items: start;
    justify-content: start;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .nav-dropdown .nav__item {
        gap: 0.39062vw; } }
    @media (any-hover: hover) {
      .nav-dropdown .nav__item:hover > .nav__link {
        color: var(--accent-light-primary);
        background-color: var(--bg-white); } }
  .nav-dropdown .nav__link {
    padding: 0.625rem 0.9375rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .nav-dropdown .nav__link {
        padding: 0.39062vw 0.9375rem; } }
    @media screen and (max-width: 106.25rem) {
      .nav-dropdown .nav__link {
        padding: 0.71875rem 0.9375rem; } }
    .nav-dropdown .nav__link::before {
      display: none; }
  .nav-dropdown .nav__arrow {
    width: 1.125rem;
    height: 1.125rem;
    fill: var(--accent-dark-primary); }
    @media screen and (min-width: 160.0625rem) {
      .nav-dropdown .nav__arrow {
        width: 0.70312vw;
        height: 0.70312vw; } }

[data-nav-overflow-list] .nav__link {
  padding: 0; }

.nav-europa {
  --list-gap: 0.625rem;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 2.5rem 0;
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background-color: var(--bg-white);
  border-top: solid 1px var(--stroke-dark-secondary);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--animation-timing) var(--cubic-bezier) 0.25s, visibility var(--animation-timing) var(--cubic-bezier) 0.25s; }
  @media screen and (min-width: 160.0625rem) {
    .nav-europa {
      --list-gap: 0.39062vw;
      padding: 1.5625vw 0;
      border-top-width: 0.03906vw; } }
  .nav-europa__wrapper {
    padding: 0 var(--container-offset);
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-count: 4;
    gap: var(--grid-column-gap);
    overflow: auto;
    overscroll-behavior: contain; }
    .nav-europa__wrapper::-webkit-scrollbar {
      width: 0.8125rem; }
      @media screen and (min-width: 160.0625rem) {
        .nav-europa__wrapper::-webkit-scrollbar {
          width: 0.50781vw; } }
    .nav-europa__wrapper::-webkit-scrollbar-track {
      background: var(--bg-white); }
    .nav-europa__wrapper::-webkit-scrollbar-thumb {
      background-color: var(--stroke-dark-secondary);
      border-radius: 0;
      border: 4px solid var(--bg-white);
      cursor: pointer; }
      @media screen and (min-width: 160.0625rem) {
        .nav-europa__wrapper::-webkit-scrollbar-thumb {
          border-radius: 39.02344vw;
          border-width: 0.15625vw; } }
      @media (any-hover: hover) {
        .nav-europa__wrapper::-webkit-scrollbar-thumb:hover {
          background-color: var(--accent-light-primary); } }
  .nav-europa ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--list-gap);
    list-style: none; }
  .nav-europa li {
    width: 100%;
    display: block; }
    .nav-europa li > ul {
      margin-top: var(--list-gap); }
      .nav-europa li > ul > li > ul {
        padding-left: calc(var(--grid-column-gap) / 2); }
    .nav-europa li a {
      width: 100%;
      display: block;
      color: var(--text-dark-secondary);
      font: var(--font-body-S);
      text-decoration: none;
      transition: color var(--animation-timing) var(--cubic-bezier); }
      @media (any-hover: hover) {
        .nav-europa li a:hover {
          color: var(--accent-light-primary); } }
  .nav-europa__column {
    width: 100%;
    height: auto; }
    .nav-europa__column > ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: var(--grid-column-gap); }
      .nav-europa__column > ul > li > a {
        color: var(--text-dark-primary);
        font: var(--font-body-M);
        font-weight: 500; }
    .nav-europa__column_solo > ul {
      gap: var(--list-gap); }

[data-ue-catalog] {
  position: unset; }
  @media (any-hover: hover) {
    [data-ue-catalog]:hover .nav-europa {
      opacity: 1;
      visibility: visible;
      pointer-events: all; }
    [data-ue-catalog]:hover > .nav__link::before {
      content: "";
      position: absolute;
      top: -2.8125rem;
      left: -2.8125rem;
      width: calc(100% + 2.8125rem * 2);
      height: calc(100% + 2.8125rem * 2);
      background-color: transparent;
      opacity: 0; } }
  @media screen and (any-hover: hover) and (min-width: 160.0625rem) {
    [data-ue-catalog]:hover > .nav__link::before {
      top: -1.75781vw;
      left: -1.75781vw;
      width: calc(100% + 1.75781vw * 2);
      height: calc(100% + 1.75781vw * 2); } }

.header-cart {
  position: relative; }
  .header-cart .button__icon {
    position: relative;
    z-index: 1; }
  .header-cart__count {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    z-index: 2;
    padding: 0 0.125rem;
    min-width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-light-primary);
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: var(--accent-light-primary);
    border-radius: var(--radius-infinity);
    transform: scale(0);
    transition: transform var(--animation-timing-small) var(--cubic-bezier); }
    .header-cart__count_active {
      transform: scale(1); }
    @media screen and (min-width: 160.0625rem) {
      .header-cart__count {
        top: -0.3125vw;
        right: -0.3125vw;
        padding: 0 0.07812vw;
        min-width: 0.625vw;
        height: 0.625vw;
        font-size: 0.39062vw; } }
    @media screen and (max-width: 85rem) {
      .header-cart__count {
        top: 0;
        right: -0.625rem; } }
    @media screen and (max-width: 39.375rem) {
      .header-cart__count {
        right: -0.75rem; } }

.header-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.25rem;
  user-select: none; }
  @media screen and (min-width: 160.0625rem) {
    .header-social {
      gap: 0.78125vw; } }
  .header-social__item {
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    text-decoration: none; }
    @media (any-hover: hover) {
      .header-social__item:hover .header-social__icon {
        fill: var(--accent-light-secondary); } }
    .header-social__item::before {
      content: "";
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      width: calc(100% + 0.625rem * 2);
      height: calc(100% + 0.625rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .header-social__item::before {
          top: -0.39062vw;
          left: -0.39062vw;
          width: calc(100% + 0.39062vw * 2);
          height: calc(100% + 0.39062vw * 2); } }
  .header-social__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--accent-dark-primary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .header-social__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .header-social__icon {
        width: 1.125rem;
        height: 1.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .header-social__icon {
        width: 1rem;
        height: 1rem; } }

.header-call {
  min-width: 8.4375rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--text-dark-primary);
  text-decoration: none; }
  .header-call__phone {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    text-decoration: none;
    text-wrap: nowrap;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media (any-hover: hover) {
      .header-call__phone:hover {
        color: var(--accent-light-secondary); } }
  .header-call__link {
    padding: 0;
    border: none;
    background: none;
    color: var(--accent-light-primary);
    font: 400 1rem/150% var(--font-primary);
    text-decoration: underline;
    text-wrap: nowrap;
    transition: color var(--animation-timing) var(--cubic-bezier);
    cursor: pointer; }
    @media screen and (min-width: 160.0625rem) {
      .header-call__link {
        font: 400 0.625vw/150% var(--font-primary); } }
    @media (any-hover: hover) {
      .header-call__link:hover {
        color: var(--accent-light-secondary); } }

.header-phone {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.625rem;
  text-decoration: none; }
  @media screen and (min-width: 160.0625rem) {
    .header-phone {
      gap: 0.39062vw; } }
  @media (any-hover: hover) {
    .header-phone:hover .header-phone__icon {
      fill: var(--accent-light-secondary); }
    .header-phone:hover .header-phone__text {
      color: var(--accent-light-secondary); } }
  .header-phone::before {
    content: "";
    position: absolute;
    top: -0.625rem;
    left: -0.625rem;
    width: calc(100% + 0.625rem * 2);
    height: calc(100% + 0.625rem * 2);
    background-color: transparent;
    opacity: 0; }
    @media screen and (min-width: 160.0625rem) {
      .header-phone::before {
        top: -0.39062vw;
        left: -0.39062vw;
        width: calc(100% + 0.39062vw * 2);
        height: calc(100% + 0.39062vw * 2); } }
    @media screen and (max-width: 39.375rem) {
      .header-phone::before {
        content: "";
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        width: calc(100% + 0.5rem * 2);
        height: calc(100% + 0.5rem * 2);
        background-color: transparent;
        opacity: 0; } }
  @media screen and (max-width: 39.375rem) and (min-width: 160.0625rem) {
    .header-phone::before {
      top: -0.3125vw;
      left: -0.3125vw;
      width: calc(100% + 0.3125vw * 2);
      height: calc(100% + 0.3125vw * 2); } }
  .header-phone__icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: var(--accent-dark-primary);
    transition: fill var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .header-phone__icon {
        width: 0.78125vw;
        height: 0.78125vw; } }
    @media screen and (max-width: 106.25rem) {
      .header-phone__icon {
        width: 1.125rem;
        height: 1.125rem; } }
    @media screen and (max-width: 39.375rem) {
      .header-phone__icon {
        width: 1rem;
        height: 1rem; } }
    @media screen and (max-width: 39.375rem) {
      .header-phone__icon {
        fill: var(--accent-light-primary); } }
  .header-phone__text {
    color: var(--text-dark-primary);
    font: var(--font-body-XS);
    white-space: nowrap;
    transition: color var(--animation-timing) var(--cubic-bezier); }
    @media screen and (max-width: 39.375rem) {
      .header-phone__text {
        display: none; } }
