.header-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: rem(20);

  user-select: none;

  @include mediaBigDesktop {
    gap: big(20);
  }

  &__item {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;

    text-decoration: none;

    @include hover {
      & .header-social {
        &__icon {
          fill: var(--accent-light-secondary);
        }
      }
    }

    &::before {
      @include pseudo(10) {}
    }
  }

  &__icon {
    width: rem(20);
    height: rem(20);

    fill: var(--accent-dark-primary);

    transition: fill var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      width: big(20);
      height: big(20);
    }

    @include mediaLaptop {
      width: rem(18);
      height: rem(18);
    }

    @include mediaMobile {
      width: rem(16);
      height: rem(16);
    }
  }
}
