.header-cart {
  position: relative;

  & .button {
    &__icon {
      position: relative;
      z-index: 1;
    }
  }

  &__count {
    position: absolute;
    top: rem(-8);
    right: rem(-8);
    z-index: 2;

    padding: 0 rem(2);
    min-width: rem(16);
    height: rem(16);
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--text-light-primary);
    font-size: rem(10);
    font-weight: 400;
    line-height: 1.5;
    text-align: center;

    background-color: var(--accent-light-primary);
    border-radius: var(--radius-infinity);
    
    transform: scale(0);
    
    transition: transform var(--animation-timing-small) var(--cubic-bezier);
    
    &_active {
      transform: scale(1);
    }

    @include mediaBigDesktop {
      top: big(-8);
      right: big(-8);

      padding: 0 big(2);
      min-width: big(16);
      height: big(16);

      font-size: big(10);
    }

    @include mediaTablet {
      top: 0;
      right: rem(-10);
    }

    @include mediaMobile {
      right: rem(-12);
    }
  }
}
